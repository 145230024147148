import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { sendAllUserCommunication } from '../../../services/apis/notificationManagement/NMAPI';

export default function EmailTemplatePage() {

    const { emailTemplate, getEmailTemplateById, updateEmailTemplateById, templateId, setTemplateId } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc, token, logout } = useLogin()

    const [subject, setSubject] = useState("")
    const [template, setTemplate] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    const { id } = useParams()

    useEffect(() => {
        setTemplateId(id)
    }, [])

    useEffect(() => {
        if (templateId) {
            try {
                setCurrModule(configs.modules.mnt)
                if (allowedFunc.includes(configs.functionList['notification_management:notification_management'])) {
                    (async () => {
                        await getEmailTemplateById()
                    })()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        }
    }, [templateId])

    useEffect(() => {
        try {
            setSubject(emailTemplate.email_subject)
            setTemplate(emailTemplate.email_template)
        } catch (error) {

        }
    }, [emailTemplate])


    // update email template
    async function updateTemplate() {
        try {

            document.getElementById("emailBtn").classList.toggle("d-none")
            document.getElementById("emailBtnLoader").classList.toggle("d-none")

            if (!subject.trim()) {
                setError("Please provide a valid subject.")
            } else if (!template.trim()) {
                setError("Please provide a valid template.")
            } else {
                let response = await updateEmailTemplateById(subject, template)
                if (response === "success") {
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("emailBtn").classList.toggle("d-none")
            document.getElementById("emailBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // send notification to all
    async function sendNotificationToAll() {
        try {

            document.getElementById("sendBtn").classList.toggle("d-none")
            document.getElementById("sendBtnLoader").classList.toggle("d-none")


            let response = await sendAllUserCommunication(token)
            if (response && response.status === 200) {
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout()
            } else {
                setError(response)
            }


            document.getElementById("sendBtn").classList.toggle("d-none")
            document.getElementById("sendBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmEmailContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-2 mr-3">
                            <div className="col-md-9 text-left">
                                <p className="h4 text-custom-grey">Email Notification Template - {emailTemplate.name}</p>
                                <p>{emailTemplate.description}</p>
                            </div>
                            <div className="col-md-3 text-left text-md-right">
                                <Link to="/notificationTemplateManagement"><i className="fas fa-id-card-alt mr-2"></i>Manage Templates</Link>
                            </div>
                        </div>
                        {emailTemplate ?
                            <div className="mt-2 mb-4 mr-4 nm-template">
                                <p className="text-primary h6 my-4">Email Template Variables</p>

                                {<div className="row pl-3">
                                    {emailTemplate.variables ?
                                        emailTemplate.variables.map((element, key) => {
                                            return (
                                                <div key={`emailTemplate` + key} className="d-flex align-items-center justify-content-center variable-card ml-2">
                                                    <div className="col-10">
                                                        <p className="text-custom-grey p-0 m-0">{element.name} ({element.symbol})</p>
                                                    </div>
                                                    <div className="col-2 text-right">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            navigator.clipboard.writeText(element.symbol);
                                                        }}><i className="far fa-copy text-primary cursor" ></i></a>
                                                    </div>
                                                </div>

                                            )
                                        })

                                        : null}
                                </div>}
                                <p className="text-primary h6 mt-5 pl-0 ml-0">Email Template Admins</p>
                                <div className="pr-3">
                                    <form action="">
                                        <div className="form-group">
                                            <label htmlFor="email_title">Email Subject</label>
                                            <input type="text" className="form-control" value={subject ? subject : ""} onChange={(e) => {
                                                setSubject(e.target.value)
                                            }} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email_body">Email Template</label>
                                            <textarea name="email_body" id="email_body" className="w-100 form-control" rows="10" value={template ? template : ""} onChange={(e) => {
                                                setTemplate(e.target.value)
                                            }}></textarea>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger" id="templateError">{error}</p>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-12 d-flex justify-content-center">
                                                <button id="emailBtn" type="submit" className="btn btn-primary" onClick={(e) => {
                                                    e.preventDefault()
                                                    updateTemplate()
                                                }}>Update</button>
                                                <div id="emailBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                {/* {id && parseInt(id) === 86 ? <>
                                                    <button id="sendBtn" type="submit" className="btn btn-primary ml-2" onClick={(e) => {
                                                        e.preventDefault()
                                                        sendNotificationToAll()
                                                    }}>Send Notification</button>
                                                    <div id="sendBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                </> : null} */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : <div className="loader"></div>}
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}